import React, { useState } from "react"
import PropTypes from "prop-types"
import Breadcrumb from '../breadCrumb'
import ReedmakerConfig from "../../../content/reedmaker.yaml"
import { useCart } from '../../hooks/useCart'
import { ProductImage } from './productImage'

function ProductDetails({ id, store, content, title, slug, image, price, hardnessOptions }) {
  const { selectOptions } = ReedmakerConfig
  const [quantity, setQuantity] = useState(1)
  const [hardness, setHardness] = useState(hardnessOptions ? 1 : undefined)
  const { inCart, addItem, updateItemQuantity } = useCart();

  return (
    <div className="lg:flex -mx-6">
      <div className="mb-8 px-6 md:mb-0 lg:w-1/2">
        <Breadcrumb baseUri={store.uri} baseTitle={store.title} pageTitle={title} />
        <div className="w-full overflow-hidden relative bg-products rounded-lg py-4">
          <ProductImage image={image} />
        </div>
      </div>

      <div className="px-6 md:py-3 lg:w-1/2">
        <h1 className="font-bold text-3xl md:text-6xl mb-3 text-primary leading-tight">
          {title}
        </h1>

        <div className="mb-6">
          <p className="font-semibold text-2xl text-slategray">
            {"$" + price.toFixed(2)}
          </p>
        </div>


        <div className="mb-6">
          <div className="leading-loose text-lightgray content-block">
            {content}
          </div>
        </div>

        <div className="md:flex md:flex-wrap -mx-3">
          { hardnessOptions && 
            <div className="md:w-3/4 px-3 mb-6">
              <label
                className="block text-sm font-bold tracking-widest uppercase mb-2 text-slategray"
                htmlFor="style"
              >
                {selectOptions.hardness.label}
                </label>

              <div className="relative">
                <select
                  id={selectOptions.hardness.id}
                  value={hardness}
                  className="block appearance-none w-full bg-products border-2 border-gainsboro focus:border-slategray px-4 py-3 pr-8 focus:outline-none focus:bg-white text-slategray focus:text-slategray rounded-lg"
                  onChange={e => {
                    setHardness(parseInt(e.currentTarget.value))
                  }}
                >
                  {selectOptions.hardness.options
                    .map((v, k) => ({ value: k+1, label: v }))
                    .map(({ value, label }) => (
                      <option key={value} value={value}>
                        {label}
                      </option>
                    ))}
                </select>

                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-4 text-slategray">
                  <svg
                    className="fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                  </svg>
                </div>
              </div>
            </div>
          }

          <div className="md:w-1/4 px-3 mb-6">
            <label
              className="block text-sm font-bold tracking-widest uppercase mb-2 text-slategray"
              htmlFor="quantity"
            >
              {selectOptions.quantity.label}
              </label>

            <div className="relative">
              <select
                id={selectOptions.quantity.id}
                value={quantity}
                className="block appearance-none w-full bg-products border-2 border-gainsboro focus:border-slategray px-4 py-3 pr-8 focus:outline-none focus:bg-white text-slategray focus:text-slategray rounded-lg"
                onChange={e => setQuantity(parseInt(e.currentTarget.value))}
              >
                {selectOptions.quantity.options
                  .map(i => ({ value: i, label: i }))
                  .map(({ value, label }) => (
                    <option key={value} value={value}>
                      {label}
                    </option>
                  ))}
              </select>

              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-4 text-slategray">
                <svg
                  className="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                </svg>
              </div>
            </div>
          </div>
        </div>
        <div className="mb-6">
          <button
            className="block w-full bg-red-800 hover:bg-slategray px-4 py-3 rounded-lg text-white text-sm font-bold tracking-widest uppercase focus:outline-none"
            onClick={() => {
              const productId = hardnessOptions ? `${id}-${hardness}` : id

              if (inCart(productId)) {
                updateItemQuantity(productId, quantity);
              } else {
                addItem({ 
                  id: productId, 
                  price, 
                  image, 
                  name: title, 
                  hardness: hardnessOptions ? selectOptions.hardness.options[hardness-1] : undefined,
                }, quantity )
              }
            }}
          >
            Add to cart
            </button>
        </div>
      </div>
    </div>
  );
}

ProductDetails.defaultProps = {
  hardnessOptions: false,
}

ProductDetails.propTypes = {
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    image: PropTypes.object,
    price: PropTypes.number.isRequired,
    hardnessOptions: PropTypes.bool,  
}

export default ProductDetails
