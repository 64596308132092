import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ProductDetails from "../components/store/productDetails"
import Testimonial from '../components/review/testimonial'
import contentParser from 'gatsby-wpgraphql-inline-images';
import ReedmakerConfig from "../../content/reedmaker.yaml"
import { Link } from "gatsby"


const SectionHeader = ({ title }) => {
    return (
        <div className="pt-12">
            <h3 className="font-bold text-3xl mb-3">
                {title}
            </h3>
            <hr className="border-b border-gainsboro w-10" />
        </div>
    )
}

const ProductPage = (props) => {
    const { pageContext } = props
    const { title, content, databaseId, slug, featuredImage, acfProduct } = pageContext.node
    const { store } = pageContext
    const wpContent = content ? contentParser({ content }, pageContext.plugins.wpImage) : undefined;
    
    return (
        <Layout>
            <SEO title={title} description={title} />
            <div className="container mx-auto p-6 py-16">
                <div className="flex flex-wrap">
                    <section className={`w-full p-3`}>                  
                        <div id="main-content-block">
                            <ProductDetails
                                key={databaseId}
                                store={store}
                                title={title}
                                id={databaseId}
                                slug={slug}
                                content={wpContent}
                                image={featuredImage}
                                price={acfProduct.price}
                                hardnessOptions={acfProduct.hardnessOptions}
                            />
                        </div>
                    </section>

                    <section className={`w-full p-3`}>
                        <SectionHeader title={`Customer Reviews`}/>
                        <Testimonial 
                            center={false}    
                            description={ReedmakerConfig.content.home.testimonial.description}
                            name={ReedmakerConfig.content.home.testimonial.name} 
                        />
                        <span className="italic text-gray-600 underline">
                            <Link to="/write-a-review">
                                Would you like to write a review?
                            </Link>
                        </span>
                    </section>
                    
                </div>
            </div>
        </Layout>
    )
}

export default ProductPage